<template>
  <v-card>
    <v-card-title>{{ $t("statistics.title") }}</v-card-title>
    <v-tabs v-model="tab" :height="60" background-color="grey lighten-5">
      <v-tab > {{ $t("statistics.reports") }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <CompanyReports/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import CompanyReports from './tabs/CompanyReports'

export default {
  name: 'Reports',
  components: {
    CompanyReports
  },
  data () {
    return {
      tab: null
    }
  }
}
</script>

<style scoped>

</style>
